.og-grid *, .og-grid :after, .og-grid :before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.og-grid {
  text-align: left;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  list-style: none;
}

.og-grid li {
  vertical-align: top;
  margin: 10px 5px 0;
  display: inline-block;
}

.og-grid li > a, .og-grid li > a img {
  object-fit: cover;
  height: 200px;
  width: 300px;
  border: none;
  outline: none;
  display: block;
  position: relative;
}

.og-grid li.og-expanded > a:after {
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border: 15px solid #0000;
  border-bottom-color: #ddd;
  margin: -20px 0 0 -15px;
  position: absolute;
  top: auto;
  left: 50%;
}

.og-expander {
  width: 100%;
  text-align: left;
  height: 0;
  background: #ddd;
  margin-top: 10px;
  position: absolute;
  top: auto;
  left: 0;
  overflow: hidden;
}

.og-expander-inner {
  height: 100%;
  padding: 50px 30px;
}

.og-close {
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.og-fullimg, .og-details {
  width: 50%;
  float: left;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.og-details {
  padding: 0 40px 0 20px;
}

.og-fullimg {
  text-align: center;
}

.og-fullimg img {
  max-height: 100%;
  max-width: 100%;
  display: inline-block;
}

.og-loading {
  width: 20px;
  height: 20px;
  background: #ddd;
  border-radius: 50%;
  margin: -25px 0 0 -25px;
  animation: .5s ease-in-out infinite both loader;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ccc;
}

.og-previous, .og-next {
  height: 23px;
  width: 14px;
  margin-top: -20px;
  line-height: 40px;
  position: absolute;
  top: 50%;
}

.og-previous {
  background-image: url("arrow-left.95334fa0.png");
  left: 10px;
}

.og-previous:hover {
  background-image: url("arrow-left-hover.1c698abe.png");
}

.og-next {
  background-image: url("arrow-right.220ef5c6.png");
  right: 5px;
}

.og-next:hover {
  background-image: url("arrow-right-hover.858e4c4c.png");
}

@keyframes loader {
  0% {
    background: #ddd;
  }

  33% {
    background: #ccc;
    box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ddd;
  }

  66% {
    background: #ccc;
    box-shadow: 0 0 1px #ccc, 15px 30px 1px #ddd, -15px 30px 1px #ccc;
  }
}

@media screen and (max-width: 830px) {
  .og-expander h3 {
    font-size: 32px;
  }

  .og-expander p {
    font-size: 13px;
  }

  .og-expander a {
    font-size: 12px;
  }
}

@media screen and (max-width: 650px) {
  .og-details {
    float: none;
    width: 100%;
  }
}

/*# sourceMappingURL=index.1f9a2cf6.css.map */
